<template>
  <div class="w1092 pt60">
    <div class="board">
      <div class="boardrd first">
        <span class="nicon ignbg ml10">일반</span>
        <span class="ml10"># 배팅한도 #</span>
      </div>
      <div class="boardrd">
        <span>win</span><span class="ml10">2025.04.02 14:23</span>
      </div>
      <div class="boardbox">
        <pre>
-카지노 배팅 상한 500만원

-카지노 당첨 상한 1천만원

- 라이트닝 바카라등 배당게임의 경우 최대당첨금액 500만원으로 제한합니다 그이상 당첨될 경우 회수처리됩니다

-사이드 배팅 (타이 페어 등) 어떠한 경우라도 1회배팅시에는 당첨금1천만원까지 적용됩니다.

-슬롯 배팅 상한 3만원 (프리스핀 100만원)

-슬롯 당첨 상한 3천만원 초과된 당첨금에 대하여서는 회수

해당 내용 꼭 참고 하시어 이용 부탁드리겠습니다.
        </pre>
      </div>
    </div>

    <div class="boardbtn">
      <!-- <a class="blsbtn" v-if="prevId" @click="onClickPrevNext('prev')">{{$t('front.board.prev')}}</a> -->
      <a class="blsbtn" @click="goPageByName('noticeList')">{{$t('front.board.list')}}</a>
      <!-- <a class="blsbtn" v-if="nextId" @click="onClickPrevNext('next')">{{$t('front.board.next')}}</a> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'read3',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
